<template>
    <div class="LiveCourse">
        <!-- 直播回放课程顶部 -->
        <div class="VideoCourse_top">
            <div class="VideoCourse_top_left" @click="ClickCourseCatalogue">展开课程目录</div>
            <div class="VideoCourse_top_center">
                <span class="lessonTitle">{{lessonTitle}}</span>
                <span>{{teacherName}}</span>
            </div>
            <div class="VideoCourse_top_left" @click="ClickSeatwork">展开课堂作业</div>
        </div>
        <!-- 课程目录 -->
        <transition name="ivu-anim-slide-left">
            <div class="CourseCatalogue_content" v-show="ShowCourseCatalogue">
                <div class="CourseCatalogue_tit" @click="ClickCourseCatalogue">
                    <span class="shouqi">收起课程目录</span>
                    <Icon type="ios-arrow-dropleft" />
                </div>
                <!-- 课程目录 -->
                <div class="CourseCatalogue">
                    <div class="subject_item" v-for="(item,index) in subjectList" :key="item.subjectId">
                        <div class="subject_item_tit" @click="Clicksubject(item,index)">
                            <p>{{item.subjectTitle}}</p>
                            <Icon type="ios-arrow-forward" v-show="!item.showContent"/>
                            <Icon type="ios-arrow-down" v-show="item.showContent"/>
                        </div>
                        <!-- 章节列表 -->
                        <transition name="ivu-anim-slide-up">
                            <div class="chapterList" v-show="item.showContent" >
                                <div class="chapter_item" v-for="(item2,index2) in item.chapters" :key="item2.courseChapId">
                                    <div class="chapter_item_tit" @click="Clickchapter(item2,index2)">
                                        <p><span>{{item2.chapTitle}}</span> ( 未完成{{item2.toStudyNum}}门,共{{item2.lessonNum}}门 )</p>
                                        <Icon type="ios-arrow-forward" v-show="!item2.showContent"/>
                                        <Icon type="ios-arrow-down" v-show="item2.showContent"/>
                                    </div>
                                    <!-- 课节列表 -->
                                    <transition name="ivu-anim-slide-up">
                                        <div class="lessonsList" v-show="item2.showContent">
                                            <div class="lessons_item" v-for="(item3,index3) in item2.lessons" :key="item3.courseLessonId" @click="lessonschange(item3,index3)">
                                                <div class="lessons_item_tit">
                                                    <img src="@/assets/isplay.png" alt="" v-if="courseLessonId == item3.courseLessonId">
                                                    <img src="@/assets/noplay.png" alt="" v-if="courseLessonId != item3.courseLessonId">
                                                    <span class="isplay" v-if="courseLessonId == item3.courseLessonId">{{item3.lessonTitle}}</span>
                                                    <span v-if="courseLessonId != item3.courseLessonId">{{item3.lessonTitle}}</span>
                                                    <!-- 视频 -->
                                                    <img src="@/assets/VideoTag.png" alt="" v-if="item3.typePlay == 3">
                                                    <!-- 直播未开始 -->
                                                    <img src="@/assets/LiveNotTag.png" alt="" v-if="item3.typePlay == 1 && item3.extendData.state == 1">
                                                    <!-- 直播中 -->
                                                    <img src="@/assets/LiveTag.png" alt="" v-if="item3.typePlay == 1 && item3.extendData.state == 5">
                                                    <!-- 直播已结束 -->
                                                    <img src="@/assets/LiveEndTag.png" alt="" v-if="item3.typePlay == 1 && item3.extendData.state == 10">
                                                    <!-- 直播回放 -->
                                                    <img src="@/assets/LivePlaybackTag.png" alt="" v-if="item3.typePlay == 1 && item3.extendData.state == 15">
                                                </div>
                                                <div class="lessons_bottom">
                                                    <div class="lessons_bottom_left">
                                                        <div class="teacher">
                                                            <img src="@/assets/teacher.png" alt="">
                                                            <span>{{item3.teacherName}}</span>
                                                        </div>
                                                        <div class="time" v-if="item3.typePlay == 1">
                                                            <img src="@/assets/time.png" alt="">
                                                            <span>{{item3.startTimeStr}}</span>
                                                        </div>
                                                    </div>
                                                    <img src="@/assets/ifplay.gif" alt="" class="ifplay" v-if="courseLessonId == item3.courseLessonId">
                                                    <img src="@/assets/isHadStudy.png" alt="" v-else-if="item3.isHadStudy">
                                                </div>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </transition>
        <!-- 课堂作业 -->
        <transition name="ivu-anim-slide-right">
            <div class="seatwork" v-show="Showseatwork">
                <div class="seatwork_tit" @click="ClickSeatwork">
                    <span class="shouqi">收起课堂作业</span>
                    <Icon type="ios-arrow-dropright" />
                </div>
                <div class="seatwork_catalog">
                    <div class="seatwork_item" v-for="(item,index) in seatworklist" :key="item.courseExerciseId" @click="ClickPapers(item)">
                        <div class="seatwork_item_tit" :class="{state:item.state == 5}">{{item.paperName}}</div>
                        <div class="seatwork_item_details">
                            <img src="@/assets/subject.png" alt="">
                            <span>共{{item.questionNum}}题，总分{{item.score}}分</span>
                        </div>
                        <div class="seatwork_item_state">
                            <span class="default" v-if="item.state == 1">未开始</span>
                            <span class="gold" v-if="item.state == 5">进行中</span>
                            <span class="green" v-if="item.state == 10">得分{{item.getScore}}分</span>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <!-- 直播 -->
        <div class="Liveplay">
            <iframe frameborder="0" :src="playBackUrl"></iframe>
            <img src="@/assets/alongClass.png" alt="" class="alongClass" @click="Clickalong">
        </div>

        <!-- 试题 -->
        <div class="middle">
            <div class="questions_content">
                <div class="questions" v-if="isquestions">
                    <!-- 加载中 -->
                    <Spin fix v-if="Subject_Loading">
                        <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                        <div>Loading</div>
                    </Spin>
                    <img src="@/assets/CourseTest.png" alt="" class="CourseTest">
                    <div class="SubjectList">
                        <div class="optionsList" v-if="questionNum != 0">
                            <Select v-model="difficultyId" style="width: 185px;margin-right: 20px;">
                                <Option v-for="item in difficulty" :value="item.id" :key="item.id">{{item.name}}</Option>
                            </Select>
                            <Select v-model="fromTypeId" style="width: 185px;margin-right: 20px;">
                                <Option v-for="item in fromType" :value="item.id" :key="item.id">{{item.name}}</Option>
                            </Select>
                            <Select v-model="knowledgePointId" style="width: 185px;margin-right: 20px;">
                                <Option v-for="item in knowledgeList" :value="item.knowledgePointId" :key="item.knowledgePointId">{{item.title}}</Option>
                            </Select>
                            <Button type="primary" @click="ExtractingQuestion">随机抽题</Button>
                        </div>
                        <div class="List_item" v-for="(item,index) in SubjectList" :key="item.questionId">
                            <Test :SubjectList="item" :questionIndex="index+1" v-if="Testupdate"/>
                        </div>
                        <div class="last" v-if="SubjectList.length != 0">已经是最后一题啦</div>
                        <div class="Ellipsis" v-if="questionNum == 0">
                            <img src="@/assets/report_Ellipsis.png" alt="">
                            <p>该课程没有相关习题哟</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CourseLessonStudy, GetCourseLessonQuestion, GetCourseLessonDetail } from "@/api/Course"
import { TotalSchedule, CoursePapers} from "@/api/Home";
import Test from "@/views/Course/Test"
export default {
    name: 'PlaybackCourse',
    props: {},
    components: {
        Test
    },
    data () {
       return {
            playBackUrl: '',
            courseLessonId: '',
            lessonTitle: '',
            teacherName: '',
            courseId: '',
            // 课堂作业显示隐藏
            Showseatwork: false,
            // 课程目录显示隐藏
            ShowCourseCatalogue: false,
            // 科目列表
            subjectList: [],
            seatworklist: [],
            // 视频开始播放时间
            startTime: '',
            timer: '',
            SubjectList: [],
            Subject_Loading: false,
            // 总题目数
            questionNum: 0,
            // 难度可选项
            difficulty:[
                {
                    name: '所有难易度',
                    id: 0
                }
            ],
            difficultyId: 0,
            // 来源可选项
            fromType: [
                {
                    name: '所有来源',
                    id: 0
                }
            ],
            fromTypeId: 0,
            // 知识点选项列表
            knowledgeList: [
                {
                    title: '相关所有知识点',
                    knowledgePointId: 0
                }
            ],
            knowledgePointId: 0,
            // 刷新试卷组件
            Testupdate: true,
            isquestions: false
       }
    },
    created () {
        this.playBackUrl = this.$route.query.playBackUrl
        this.courseLessonId = this.$route.query.courseLessonId
        this.lessonTitle = this.$route.query.lessonTitle
        this.teacherName = this.$route.query.teacherName
        this.courseId = localStorage.courseId
    },
    mounted () {
        this.startTime = this.getCurrentTime()
        this.GetTotalSchedule()
        this.GetCoursePapers()
        this.CourseLessonStudy()
        this.GetCourseData()
        // 两分钟上报一次学习记录
        this.timer = setInterval(this.CourseLessonStudy, 1000*60*2)
    },
    beforeDestroy() {
        if (this.timer) {
          clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
        }
    },
    methods: {
        // 获取课节详情
        GetCourseData(){
            GetCourseLessonDetail(this.courseLessonId).then((res)=>{
                this.isquestions = true
                // 总题目数
                this.questionNum = res.data.questionData.questionNum
                // 难度可选项
                this.difficulty = [...this.difficulty,...res.data.questionData.difficulty]
                // 来源可选项
                this.fromType = [...this.fromType,...res.data.questionData.fromType]
                // 知识点选项列表
                this.knowledgeList = [...this.knowledgeList,...res.data.questionData.knowledgeList]
                if(res.data.questionData.questionNum != 0){
                    this.ExtractingQuestion()
                }
            })
        },

        // 获取课堂作业
        GetCoursePapers(){
            let Papersdata = {
                courseId: this.courseId,
                moduleType: "5",
                page: "1",
                limit: "500"
            }
            CoursePapers(Papersdata).then((res)=>{
                if(res.ret == 1){
                    // console.log(res)
                    this.seatworklist = res.data
                }else{
                    console.log(res.msg)
                }
                this.test_Loading = false
            })
        },

        // 展开/收起课程目录
        ClickCourseCatalogue(){
            this.ShowCourseCatalogue = !this.ShowCourseCatalogue
        },

        // 展开/收起课堂作业
        ClickSeatwork(){
            this.Showseatwork = !this.Showseatwork
        },

        // 点击课堂作业
        ClickPapers(data){
            // 打开新页面
            let { href } = this.$router.resolve({path: '/QuestionPapers',query: {paperId: data.paperId,belongId: data.belongId,belongType: data.belongType,state: data.state,userPaperId: data.userPaperId}})
            window.open(href, '_blank')
        },

        // 获取总课程表
        GetTotalSchedule(){
            TotalSchedule(this.courseId).then((res) => {
                if(res.ret == 1){
                    // 科目内容列表
                    this.subjectList = res.data.subjectList
                    this.subjectList.map((item,index)=>{
                        item.showContent=false
                        item.chapters.map((item2,index2) =>{
                            item2.showContent = false
                            item2.lessons.map((item3,index3)=>{
                                // 目录默认展开当前项
                                if(item3.courseLessonId == this.courseLessonId){
                                    item.showContent=true
                                    item2.showContent = true
                                    this.$forceUpdate();
                                }
                            })
                        })
                    })
                    // 科目章节列表
                    this.chapterList = res.data.subjectList[0].chapters
                    this.lessonsList = res.data.subjectList[0].chapters[0].lessons
                }else{
                    console.log(res.msg)
                }
            })
        },

        // 点击课程
        Clicksubject(item,index){
            this.subjectList[index].showContent=!this.subjectList[index].showContent;
            this.$forceUpdate();
        },

        // 点击章节
        Clickchapter(item,index){
            item.showContent = !item.showContent
            this.$forceUpdate();
        },

        // 点击课节
        lessonschange(item,index){
            if(item.typePlay == 1&&item.extendData.state == 1){
                this.$Message.warning('直播还未开始');
            }else if(item.typePlay == 1&&item.extendData.state == 5){
                // 直播
                this.$router.push({path: '/LiveCourse',query: {webUrl: item.extendData.webUrl,courseLessonId: item.courseLessonId,lessonTitle: item.lessonTitle,teacherName: item.teacherName}})
            }else if(item.typePlay == 1&&item.extendData.state == 10){
                // 直播回放未生成
                this.$router.push({path: '/LiveCourse',query: {webUrl: item.extendData.webUrl,courseLessonId: item.courseLessonId,lessonTitle: item.lessonTitle,teacherName: item.teacherName}})
            }else if(item.typePlay == 1&&item.extendData.state == 15){
                // 直播回放
                this.$router.replace({path: '/PlaybackCourse',query: {playBackUrl: item.extendData.playBackUrl,courseLessonId: item.courseLessonId,lessonTitle: item.lessonTitle,teacherName: item.teacherName}})
                this.$router.go(0);
            }else if(item.typePlay == 3){
                // 视频课程
                this.$router.push({path: '/VideoCourse',query: {id: item.courseLessonId}})
            }
        },

        // 提交课程学习记录
        CourseLessonStudy(){
            let studyData = {
                courseLessonId : this.courseLessonId,
                startTime : this.startTime,
                endTime : this.getCurrentTime(),
                videoPlayTime: ''
            }
            CourseLessonStudy(studyData).then((res)=>{
                // console.log(res)
            })
        },

        // 获取当前时间并打印
        getCurrentTime() {
            var _this = this;
        　　let yy = new Date().getFullYear();
        　　let mm = new Date().getMonth()+1;
        　　let dd = new Date().getDate();
        　　let hh = new Date().getHours();
        　　let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
        　　let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
            if (mm >= 1 && mm <= 9) {
              mm = "0" + mm;
            }
            if (dd >= 0 && dd <= 9) {
              dd = "0" + dd;
            }
        　　return yy+'-'+mm+'-'+dd+' '+hh+':'+mf+':'+ss;
        },

        // 点击随堂练习
        Clickalong(){
            this.$nextTick(function () {
	        window.scrollTo({'behavior': 'smooth', 'top': 250})
	      })
        },

        // 随机抽题
        ExtractingQuestion(){
            this.Subject_Loading = true
            let ExtractingData = {
                courseLessonId : this.courseLessonId,
                difficultyId : this.difficultyId == 0 ? "" : this.difficultyId,
                fromTypeId : this.fromTypeId == 0 ? "" : this.fromTypeId,
                knowledgePointId : this.knowledgePointId == 0 ? "" : this.knowledgePointId
            }
            GetCourseLessonQuestion(ExtractingData).then((res)=>{
                this.SubjectList = res.data
                this.Subject_Loading = false
                // 移除组件
                this.Testupdate = false
                // 在组件移除后，重新渲染组件
                // this.$nextTick可实现在DOM 状态更新后，执行传入的方法。
                this.$nextTick(() => {
                    this.Testupdate = true
                })
            })
        },
    },
}
</script> 

<style lang='less' scoped>
    .LiveCourse{
        width: 100%;
        // height: 100vh;
        .VideoCourse_top{
            position: fixed;
            top: 0;
            width: 100%;
            height: 60px;
            line-height: 60px;
            background-color: #2B2424;
            color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 99;
            .VideoCourse_top_center{
                .lessonTitle{
                    margin-right: 10px;
                }
            }
            .VideoCourse_top_left{
                width: 136px;
                height: 60px;
                line-height: 60px;
                background: #3B3333;
                cursor: pointer;
            }
        }
        .CourseCatalogue_content{
            position: fixed;
            top: 0;
            left: 0;
            width: 380px;
            height: 100vh;
            background-color: #372F2F;
            z-index: 100;
            text-align: left;
            color: #FFFFFF;
            overflow: hidden;
            overflow-y: auto;
            .CourseCatalogue_tit{
                width: 100%;
                height: 60px;
                line-height: 60px;
                background-color: #3B3333;
                font-size: 24px;
                font-weight: 400;
                padding: 0 20px;
                // margin-right: 20px;
                // text-align: right;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                .shouqi{
                    font-size: 16px;
                }
            }
            .CourseCatalogue{
                width: 380px;
                height: calc(100vh - 60px);
                text-align: left;
                background: #372F2F;
                padding: 20px 10px 0;
                overflow: hidden;
                overflow-y: auto;
                .subject_item{
                    font-size: 16px;
                    color: #FFFFFF;
                    margin-bottom: 20px;
                    .subject_item_tit{
                        width: 100%;
                        background-color: #2B2424;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 20px 10px;
                        border-radius: 2px;
                        cursor: pointer;
                    }
                    .chapterList{
                        font-size: 14px;
                        // padding: 20px;
                        .chapter_item{
                            .chapter_item_tit{
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding: 20px 10px 20px 20px;
                                border-radius: 2px;
                                cursor: pointer;
                                p{
                                    width: 90%;
                                }
                            }
                        }
                    }
                    .lessonsList{
                        font-size: 14px;
                        .lessons_item{
                            padding: 20px;
                            border-bottom: 1px solid #2B2424;
                            cursor: pointer;
                            .lessons_item_tit{
                                display: flex;
                                align-items: center;
                                color: #c1c3c5;
                                span{
                                    margin: 0 10px;
                                }
                                .isplay{
                                    color: #FB7878;
                                }
                            }
                            .lessons_bottom{
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                margin-top: 8px;
                                padding-left: 25px;
                                font-size: 12px;
                                color: #999;
                                .lessons_bottom_left{
                                    display: flex;
                                    align-items: center;
                                    .teacher{
                                        display: flex;
                                        align-items: center;
                                        span{
                                            margin-bottom: 1px;
                                        }
                                    }
                                    .time{
                                        display: flex;
                                        align-items: center;
                                        margin-left: 10px;
                                        span{
                                            margin-bottom: 1px;
                                        }
                                    }
                                    img{
                                        margin-right: 5px;
                                    }
                                }
                                .ifplay{
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
                    }
                }
            }
            .CourseCatalogue::-webkit-scrollbar {
              width: 5px;
              height: 15px;
            }
            .CourseCatalogue::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background-color: #c1c1c1;
            }
            .CourseCatalogue::-webkit-scrollbar-track {
              box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
              background: #372F2F;
              border-radius: 10px;
            }
        }
        .seatwork{
            position: fixed;
            top: 0;
            right: 0;
            width: 380px;
            height: 100vh;
            background-color: #372F2F;
            z-index: 100;
            text-align: left;
            color: #FFFFFF;
            overflow: hidden;
            overflow-y: auto;
            .seatwork_tit{
                width: 100%;
                height: 60px;
                line-height: 60px;
                background-color: #3B3333;
                font-size: 24px;
                font-weight: 400;
                // margin-left: 20px;
                padding: 0 20px;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .shouqi{
                    font-size: 16px;
                }
            }
            .seatwork_catalog{
                padding: 20px 10px 0;
                .seatwork_item{
                    width: 100%;
                    padding: 15px 10px 10px 20px;
                    background: #2B2424;
                    border-radius: 2px;
                    margin-bottom: 20px;
                    cursor: pointer;
                    .seatwork_item_tit{
                        font-size: 14px;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                    .state{
                        color: #FB7878;
                    }
                    .seatwork_item_details{
                        display: flex;
                        align-items: center;
                        margin-top: 10px;
                        color: #999999;
                        font-size: 12px;
                        span{
                            margin-left: 5px;
                        }
                    }
                    .seatwork_item_state{
                        text-align: right;
                        margin-top: -10px;
                        font-size: 11px;
                        .default{
                            padding: 0 10px;
                            color: #372F2F;
                            background-color: #AAAAAA;
                            border-radius: 0px 2px 2px 2px;
                        }
                        .gold{
                            padding: 0 10px;
                            color: #FF9600;
                            background-color: #FFF2CF;
                            border-radius: 0px 2px 2px 2px;
                        }
                        .green{
                            padding: 0 5px;
                            color: #0D5500;
                            background-color: #DDFFD7;
                            border-radius: 0px 2px 2px 2px;
                        }
                    }
                }
            }

        }
        .seatwork::-webkit-scrollbar {
          width: 5px;
          height: 15px;
        }
        .seatwork::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #c1c1c1;
        }
        .seatwork::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          background: #372F2F;
          border-radius: 10px;
        }
        .Liveplay{
            margin-top: 60px;
            width: 100%;
            height: calc(100vh - 60px);
            position: relative;
            iframe{
                width: 100%;
                height: 100%;
            }
            .alongClass{
                position: absolute;
                bottom: 8px;
                left: 16px;
                width: 70px;
                height: 24px;
                cursor: pointer;
            }
        }
        .middle{
            min-width: 1200px;
            padding: 20px 0;
            background-color: #f5f5f5;
            .questions_content{
                width: 1200px;
                margin: 0 auto;
                .questions{
                    position: relative;
                    width: 1200px;
                    min-height: 100vh;
                    text-align: left;
                    background: #FFFFFF;
                    border-radius: 12px;
                    padding: 91px 30px 77px;
                    .CourseTest{
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                    // .optionsList{}
                    .SubjectList{
                        .List_item{
                            margin-top: 40px;
                            font-size: 16px;
                            font-weight: 400;
                            color: #333333;
                        }
                        .last{
                            font-size: 16px;
                            font-weight: 400;
                            color: #333333;
                        }
                        .Ellipsis{
                            text-align: center;
                            margin-top: 105px;
                            p{
                                margin-top: 25px;
                                font-size: 16px;
                                color: #999;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
